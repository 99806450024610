import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Container, Row, Col } from '../components/grid'
import PageHeader from '../components/page-header'
import styled, { Box, css} from '@xstyled/styled-components'
import { Text } from '../components/text'
import SubHeader from '../components/sub-header'
import Button from '../components/button'
import startup1 from '../images/startup-1.svg'
import startup2 from '../images/startup-2.svg'
import startup3 from '../images/startup-3.svg'
import startup4 from '../images/startup-4.svg'
import startup5 from '../images/startup-5.svg'
import startup6 from '../images/startup-6.svg'
import startup7 from '../images/startup-7.svg'
import startup8 from '../images/startup-8.svg'
import { navigate } from 'gatsby'
import { breakpoints } from '@xstyled/system'

const IconText = styled(Text)`
  font-family: 'MTTMilano';
  font-size: 1.5em;
  line-height: 1.5em;
  text-align: center;
  ${breakpoints({
    xs: css`
      font-size: 1.25em;
    `,
    lg: css`
      font-size: 1.5em;
    `,
  })}
`

const JoinBox = styled(Box)`
  margin: 5 0;
  text-align: center;
`

const JoinCta = styled(Text)`
  ${breakpoints({
    xs: css`
      font-size: 1.25em;
    `,
    lg: css`
      font-size: 1.5em;
    `,
  })}
`

const JoinButton = styled(Button)`
  margin: 5 auto;
`

const StyledContainer = styled(Container)(
  breakpoints({
    xs: css`
      padding-top: 5;
      padding-bottom: 5;
    `,
    md: css`
      padding-top: 5;
      padding-bottom: 5;
    `,
    lg: css`
      padding-top: 5;
      padding-bottom: 5;
    `,
  })
)

const Startup = () => (
  <>
    <SEO title="Startup" />
    <Layout>
      <PageHeader>Startup?</PageHeader>

      <StyledContainer>
        <SubHeader mb="5" width={2 / 3} mx="auto">
          Are you the founder of an exciting startup looking for investment?
        </SubHeader>
        <IconText textAlign="center" mb={5}>
          Do you fit the following criteria?
        </IconText>
        <Row justifyContent="center" alignItems="flexStretch">
          <Col col={{ xs: 1, lg: 1 / 3 }}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              mb={5}
            >
              <Box height={140} display="flex" alignItems="center">
                <img src={startup1} />
              </Box>
              <IconText>Pre-seed, Seed or A round</IconText>
            </Box>
          </Col>
          <Col col={{ xs: 1, lg: 1 / 3 }}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              mb={5}
            >
              <Box height={140} display="flex" alignItems="center">
                <img src={startup2} />
              </Box>
              <IconText>A clear route to market and to profitability</IconText>
            </Box>
          </Col>
          <Col col={{ xs: 1, lg: 1 / 3 }}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              mb={5}
            >
              <Box height={140} display="flex" alignItems="center">
                <img src={startup3} />
              </Box>
              <IconText>Encouraging customer appetite and growth</IconText>
            </Box>
          </Col>
          <Col col={{ xs: 1, lg: 1 / 3 }}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              mb={5}
            >
              <Box height={140} display="flex" alignItems="center">
                <img src={startup4} />
              </Box>
              <IconText>
                Experienced Founder and/or embedded senior team
              </IconText>
            </Box>
          </Col>
          <Col col={{ xs: 1, lg: 1 / 3 }}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              mb={5}
            >
              <Box height={140} display="flex" alignItems="center">
                <img src={startup5} />
              </Box>
              <IconText>SaaS, Marketplace, Fintech or D2C</IconText>
            </Box>
          </Col>
        </Row>
        <JoinBox>
          <JoinCta>We are currently not making new investments.</JoinCta>
        </JoinBox>
      </StyledContainer>

      <Box backgroundColor="secondary">
        <StyledContainer>
          <SubHeader mb="5" mt={0} mx="auto">
            What we offer:
          </SubHeader>
          <Row justifyContent="center" alignItems="flexStretch">
            <Col col={{ xs: 1, lg: 1 / 3 }}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                mb={{ xs: 5, md: 0 }}
              >
                <Box height={240} display="flex" alignItems="center">
                  <img src={startup6} />
                </Box>
                <IconText>
                  Hands on guidance from us &ndash; this is our full-time job
                </IconText>
              </Box>
            </Col>
            <Col col={{ xs: 1, lg: 1 / 3 }}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                mb={{ xs: 5, md: 0 }}
              >
                <Box height={240} display="flex" alignItems="center">
                  <img src={startup7} />
                </Box>
                <IconText>
                  Join the Horseplay Ventures platform allowing cross-learning
                  and sharing amongst all our investments
                </IconText>
              </Box>
            </Col>
            <Col col={{ xs: 1, lg: 1 / 3 }}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                mb={{ xs: 5, md: 0 }}
              >
                <Box height={240} display="flex" alignItems="center">
                  <img src={startup8} />
                </Box>
                <IconText>
                  Help with raising present or future fundraising
                </IconText>
              </Box>
            </Col>
          </Row>
        </StyledContainer>
      </Box>

    </Layout>
  </>
)

export default Startup
